<template>
  <div>
    <b-input-group>
      <template #prepend>
        <b-form-select
          v-model="phoneCode"
          :options="phoneCodeList"
          style="background-color: #f4f4f4;"
        />
      </template>
      <b-form-input
        v-model="customerPhone"
        type="number"
        :class="
          requireCustomerPhone
            ? 'bg-[#FFEDED] border-[1px] border-[#E31A1A]'
            : 'bg-[ffffff]'
        "
        :formatter="validatePhone"
        @paste.prevent="handlePaste"
        @keypress="blockCharacter($event)"
      />
    </b-input-group>
    <div v-if="messageError !== ''">
      <small class="mr-1 text-primary"> *{{ messageError }} </small>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    valuePhone: {
      type: String,
      default: '',
    },
    checkWa: {
      type: Boolean,
      default: false,
    },
    phoneUsed: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      phoneCode: '+62',
      phoneCodeList: [
        { value: '+62', text: 'ID +62' },
        { value: '+65', text: 'SG +65' },
        { value: '+60', text: 'MY +60' },
      ],
      customerPhone: '',
      requireCustomerPhone: null,
      messageError: '',
    }
  },
  watch: {
    valuePhone: {
      handler(newVal) {
        if (newVal.length > 9) {
          let phone = newVal
          const startsWithZero = newVal.startsWith('0')
          if (startsWithZero) {
            phone = newVal.slice(1)
          }
          this.customerPhone = this.validatePhone(phone)
        }
      },
      immediate: true,
    },
    requireCustomerPhone: {
      handler() {
        this.updateValue()
      },
      deep: true,
    },
    customerPhone: {
      handler() {
        this.updateValue()
      },
      deep: true,
    },
  },
  methods: {
    updateValue() {
      this.$emit('update:dataForParent', {
        customerPhone: this.customerPhone,
        requireCustomerPhone: this.requireCustomerPhone,
        messageError: this.messageError,
      })
    },
    blockCharacter($event) {
      this.messageError = ''
      const key = ['e', '+', '.', '-', ',']
      if (key.includes($event.key)) {
        $event.preventDefault()
      } else {
        this.validatePhone($event.target.value)
      }
    },
    validatePhone(value) {
      this.messageError = ''
      const phone = value.replace(/[^\d]/g, '')
      const startsWithZero = phone.startsWith('0')
      const isRequired = phone.length === 0
      this.requireCustomerPhone = phone.length < 9 || phone.length > 15 || startsWithZero || isRequired

      if (isRequired) {
        this.messageError = 'Wajib diisi ya'
        return value
      }

      if (value.startsWith('62')) {
        return value.slice(2)
      }

      if (startsWithZero) {
        this.messageError = 'Dilarang di mulai angka 0'
        this.isWhatsapp = null
        return value.slice(1)
      }

      if (phone.length < 9) {
        this.messageError = 'Minimal 9 angka'
      }

      if (phone.length > 15) {
        this.messageError = 'Maksimal 15 karakter'
        return value.substring(0, 15)
      }

      if (this.phoneUsed && !this.requireCustomerPhone) {
        this.requireCustomerPhone = true
        this.checkPhoneUsed()
      }
      return value
    },
    checkPhoneUsed: _.debounce(async function () {
      await this.$http_new
        .get(
          `/auth/api/v1/user/setting/phone/check-used?phone=62${this.customerPhone}`,
        )
        .then(response => {
          const { data } = response.data
          if (data.is_used) {
            this.messageError = 'Nomor Hp sudah digunakan orang lain'
            this.requireCustomerPhone = true
          }
          this.requireCustomerPhone = false
        })
        .catch(err => {
          console.log(err.response)
        })
      this.updateValue()
    }, 1000),
    handlePaste(event) {
      const pasteContent = (
        event.clipboardData || window.clipboardData
      ).getData('text')
      const sanitizedContent = pasteContent.replace(/\D/g, '')
      this.customerPhone = sanitizedContent
      const index = this.customerPhone.indexOf('8')
      if (index !== -1) {
        const secondPart = this.customerPhone.substring(index)
        this.customerPhone = `${secondPart}`
      }
      event.preventDefault()
    },
  },
}
</script>
<style lang="scss">
.info-wa {
  background-color: #bee6fc !important;
  font-size: 12px !important;
  color: #08a0f7 !important;
}
.btn-send {
  border: 1px solid #d8d6de;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  width: 35px;
  text-align: -webkit-center;
}

.btn-send:focus {
  outline-color: #f95031 !important;
}
</style>
